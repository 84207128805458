<template>
  <div :key="componentKey">
    <CToaster :autohide="3000">
      <CToast
        :show="showSuccessToaster"
        color="success"
        header="Notification"
      >
        Success! Please refresh to retrieve latest table data!
      </CToast>
    </CToaster>

    <a-drawer
      title="Mass Update Courses"
      placement="right"
      :visible="massUpdateDrawerVisibile"
      @close="closeDrawer"
      :zIndex=99999
      :width=1200
    >
        <mass-update-courses :key="massUpdateKey" @sendToCourses="receivedFromMassUpdate"></mass-update-courses>
    </a-drawer>

    <CCard>
        <CCardHeader>
            <slot name="header">
              <CIcon name="cil-library"/>  {{ $options.name }} 
              <div class="card-header-actions">
                  <div class="row">
                      <div class="col-auto">
                          <a-dropdown>
                              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                  <CIcon name="cil-options"/>
                              </a>
                              <a-menu slot="overlay">
                                  <a-menu-item @click="openDrawer('massUpdateCourse')">
                                      <a>Mass Update Courses</a>
                                  </a-menu-item>                      
                              </a-menu>
                          </a-dropdown>
                      </div>
                  </div>
              </div>
            </slot>
        </CCardHeader>
        <CCardBody>
            <VueTabulator ref="tabulator" :options="tabulatorOptions"/>
        </CCardBody>
    </CCard>
  </div>
</template>

<script>
  import encryptStorage from "../../controllers/encryptStorage.js";
  import { mapState } from "vuex";
  import _startCase from 'lodash/startCase';
  import MassUpdateCourses from "../../views/utils/MassUpdateCourses.vue";

  export default {
    name: "Courses",
    components: { MassUpdateCourses },
    computed: {
      ...mapState(["endPoint"])
    },
    mounted() {

    },
    data() {
      return {
        massUpdateKey: 0,
        massUpdateDrawerVisibile: false,
        showSuccessToaster: false,
        tabulatorInstance: null,
        loggedInUserRole: encryptStorage.getItem('loggedInUserRole'),
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        componentKey: 0,
        tabulatorOptions: {
          placeholder:"Loading..",
          ajaxLoaderLoading:"<span>Loading Data</span>",
          ajaxURL: this.$store.state.endPoint+"/api/v1/courses/get_all_courses",
          ajaxConfig:{
                  method:"GET",
                  headers: {
                    "Authorization" : 'Bearer ' + encryptStorage.getItem("accessToken"),
                    "Access-Control-Allow-Origin": "*"
                  },
              },
          ajaxProgressiveLoad: "scroll",
          height:"70vh",
          layout:"fitData",
          rowClick: this.tabulatorRowClick,
          movableColumns: true,
          columns: [
            { field: 'title', title: 'Title' },
            { field: 'background_learners', title: 'Background of Learners' },
            { field: 'course_timings', title: 'Course Timings' },
            { field: 'training_equipment_required', title: 'Training Equipment' },
            { field: 'booking_notes', title: 'Booking Notes'},

          ]
        },
      };
    }, 
    methods: {
      receivedFromMassUpdate(){
        this.closeDrawer();
        this.showSuccessToaster = true;
      },
      closeDrawer(){
        this.massUpdateDrawerVisibile = false;
        this.massUpdateKey++;
      },
      openDrawer(type){
        if (type == 'massUpdateCourse') {
          this.massUpdateDrawerVisibile = true;
          this.massUpdateKey++;
        } 
      },
    }
  };
  
</script>
<style lang='scss'>
  @import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";
</style>

<template>
  <a-spin tip="Processing..." :spinning="spinning" >

    <CAlert v-if="errorMsgs.length > 0" color="danger">
      <strong>Error: </strong>
      <ul v-for="msg in errorMsgs" :key="msg">
        <li>{{msg}}</li>
      </ul>
    </CAlert>

    <div >
      <CButton 
        color='primary'
        :block="true"
        class="btn-sm"
        @click="massUpdate"
      >
        Mass Update
      </CButton>
    </div>

    <CCardBody class="p-0 mt-2">
      <div class="row">
        <div class="col">
          <autocomplete
            ref="autocomplete"
            placeholder="Search Course"
            :source="findCoursesEndpoint"
            input-class="form-control"
            results-property="data"
            :results-display="autocompleteFormatDisplayCourse"
            :request-headers="authHeaders"
            @selected="selectCourse"
          >
          </autocomplete>

          <CListGroup class="mt-2" v-if="selectedCourses.length > 0">
            <CListGroupItem class="p-2" color="dark">
              <strong>Selected Course(s)</strong>
            </CListGroupItem>
            <CListGroupItem class="p-1" v-for="d in selectedCourses" :key="d.id">
              {{ d.title }}
              <a @click="removeCourse(d.id)">
                <CIcon class="float-right mr-2" style="cursor: pointer;" name="cil-x"/>
              </a>
            </CListGroupItem>
          </CListGroup>
        </div>
      </div>

      <hr>
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Background of Learners"
              v-model="course.background_learners"
            >
          </CTextarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Course Timings"
              v-model="course.course_timings"
            >
          </CTextarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Training Equipment Required"
              v-model="course.training_equipment_required"
            >
          </CTextarea>
        </div>
      </div>      
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Booking Notes"
              v-model="course.booking_notes"
            >
          </CTextarea>
        </div>
      </div>

    </CCardBody>
   
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  import Autocomplete from 'vuejs-auto-complete';

  export default {
    name: "MassUpdateCourses",
    components: { Autocomplete },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        selectedCourses: [],
        course: {}
      }
    },
    methods: {
      async massUpdate(){
        let course_ids = this.selectedCourses.map(a => a.id);
        var body = {course_data: this.course, course_ids: course_ids};
        var response = await HTTPRequest.postRequest(this.endPoint, "/api/v1/courses/mass_update_courses", null, body);

        if (response.status == true) {
          this.$emit('sendToCourses', response.client);
        } else {
          this.errorMsgs.push(response.status);
        }
      },
      findCoursesEndpoint(searchText) {
        return this.endPoint + "/api/v1/bookings/find_courses?title=" + searchText;
      },
      autocompleteFormatDisplayCourse (result) {
        return result.title;
      },
      selectCourse (group) {
        let exists = this.selectedCourses.find(d => d.id == group.selectedObject.id);
        if (!exists) {
          this.selectedCourses.push(group.selectedObject);
        }
        this.$refs.autocomplete.clear();
      },
      removeCourse(course_id){
        let courses = this.selectedCourses.filter(d => d.id !== course_id);
        this.selectedCourses = courses;
      },
    }
  };
</script>


<style scoped>

</style>